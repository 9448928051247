<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              for="filter_category"
            >
              <strong>
                Category
              </strong>
            </label>
            <b-select
              id="filter_category"
              v-model="filter.category"
              :options="filterCategories"
              :disabled="state.busy || fetching.filter.categories"
              @change="onRefreshTableItemDetails"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              for="filter_item"
            >
              <strong>
                Item
              </strong>
            </label>
            <v-select
              id="filter_item"
              v-model="selected.filter.item"
              :options="list.filter.items"
              type="text"
              label="item_label"
              placeholder="search item"
              :disabled="state.busy || fetching.filter.items"
              :loading="fetching.filter.items"
            >
              <template #option="{ item_name, serial_number }">
                <div class="d-flex flex-column">
                  <span>{{ item_name }}</span>
                  <strong>{{ serial_number }}</strong>
                </div>
              </template>
              <template #no-options="">
                no available item
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableItemDetails.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="tableItemDetails"
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableItemDetails.perPage"
        :current-page="tableItemDetails.currentPage"
        :items="myProvider"
        :fields="tableItemDetails.fields"
        :sort-by.sync="tableItemDetails.sortBy"
        :sort-desc.sync="tableItemDetails.sortDesc"
        :sort-direction="tableItemDetails.sortDirection"
        :filter="tableItemDetails.filter"
        :filter-included-fields="tableItemDetails.filterOn"
      >
        <template #cell(index)="row">
          {{ tableItemDetails.currentPage * tableItemDetails.perPage - tableItemDetails.perPage + (row.index + 1) }}
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onViewItemDetail(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

        <template #cell(serial_number)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(item_detail_code)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(category_name)="row">
          <div class="text-nowrap">
            <strong class="text-success">{{ row.value }}</strong>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableItemDetails.perPage"
              :options="tableItemDetails.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableItemDetails.currentPage"
            :total-rows="tableItemDetails.totalRows"
            :per-page="tableItemDetails.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-item-detail"
      size="xl"
      scrollable
      no-close-on-backdrop
      title="Item Detail"
    >

      <b-row>

        <b-col
          cols="12"
        >
          <b-row>

            <b-col
              cols="12"
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <h4>ITEM</h4>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label
                      for="item_serial_number"
                    >
                      <strong>
                        Serial Number
                      </strong>
                    </label>
                    <b-input
                      id="item_serial_number"
                      v-model="itemDetail.item_serial_number"
                      type="text"
                      placeholder="no assigned item"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group>
                    <label
                      for="item_name"
                    >
                      <strong>
                        Name
                      </strong>
                    </label>
                    <b-input
                      id="item_name"
                      v-model="itemDetail.item_name"
                      type="text"
                      placeholder="no assigned item"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12 d-none d-md-block">
              <hr>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="item_detail_code"
                >
                  <strong>
                    Item Detail Code
                  </strong>
                </label>
                <b-input
                  id="item_detail_code"
                  v-model="itemDetail.item_detail_code"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="type_name"
                >
                  <strong>
                    Type
                  </strong>
                </label>
                <b-input
                  id="type_name"
                  v-model="itemDetail.type_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="category_name"
                >
                  <strong>
                    Category
                  </strong>
                </label>
                <b-input
                  id="category_name"
                  v-model="itemDetail.category_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <label for="serial_number">
              <strong>
                Serial Number
              </strong>
            </label>
            <b-input
              id="serial_number"
              v-model="itemDetail.serial_number"
              type="text"
              autocomplete="off"
              placeholder="no serial number"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <b-form-group>
            <label for="item_detail_name">
              <strong>
                Name
              </strong>
            </label>
            <b-input
              id="item_detail_name"
              v-model="itemDetail.item_detail_name"
              type="text"
              autocomplete="off"
              placeholder="enter name"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col cols="12 d-none d-md-block">
          <hr>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="supplier_name">
              <strong>
                Supplier
              </strong>
            </label>
            <b-input
              id="supplier_name"
              v-model="itemDetail.supplier_name"
              type="text"
              autocomplete="off"
              placeholder="enter supplier name"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="brand_name">
              <strong>
                Brand
              </strong>
            </label>
            <b-input
              id="brand_name"
              v-model="itemDetail.brand_name"
              type="text"
              autocomplete="off"
              placeholder="enter brand name"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="purchase_date">
              <strong>
                Purchase Date
              </strong>
            </label>
            <b-input
              id="purchase_date"
              v-model="itemDetail.purchase_date"
              type="text"
              placeholder="enter purchase date"
              autocomplete="off"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label for="original_cost">
              <strong>
                Cost
              </strong>
            </label>
            <b-input
              id="original_cost"
              v-model="itemDetail.original_cost"
              type="number"
              placeholder="enter cost"
              autocomplete="off"
              step="0.01"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label for="specifications">
              <strong>
                Specifications
              </strong>
            </label>
            <b-textarea
              id="specifications"
              v-model="itemDetail.specifications"
              rows="6"
              max-rows="12"
              placeholder="enter specifications"
              disabled
            />
          </b-form-group>
        </b-col>

      </b-row>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { UserItemDetailService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'UserItemDetails',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Item Details'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      fetching: {
        filter: {
          items: false,
          categories: false
        }
      },
      filter: {
        item: 'All',
        category: 'All'
      },
      list: {
        filter: {
          categories: [],
          items: []
        }
      },
      selected: {
        filter: {
          item: null
        }
      },
      itemDetail: {
        id: null,
        type_name: null,
        category_name: null,
        supplier_name: null,
        item: null,
        item_name: null,
        item_serial_number: null,
        item_label: null,
        item_detail_code: null,
        item_detail_name: null,
        serial_number: null,
        purchase_date: null,
        brand: null,
        original_cost: 0.00,
        specifications: null,
        active: 0
      },
      tableItemDetails: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'item_detail_code' },
          { key: 'category_name', label: 'category' },
          { key: 'serial_number' },
          { key: 'item_detail_name', label: 'peripheral' },
          { key: 'status_name', label: 'status', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ]
      }
    }
  },

  computed: {
    filterCategories () {
      return [{ text: 'All', value: 'All' }].concat(this.list.filter.categories)
    }
  },

  watch: {
    'selected.filter.item' (item) {
      this.filter.item = item?.id || null

      this.onRefreshTableItemDetails()
    }
  },

  mounted () {
    core.index()
    this.getFilterCategories().then(() => {
      this.getFilterItems()
    })
  },

  methods: {

    async myProvider (ctx) {
      return await UserItemDetailService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_item: this.filter.item,
          filter_category: this.filter.category,
          filter_attachable: 'all'
        })
      ).then(({ data }) => {
        this.tableItemDetails.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getFilterCategories () {
      this.fetching.filter.categories = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getCategories('parent=0').then(({ data }) => {
          this.list.filter.categories = data.map(({ id, category_name }) => ({ value: id, text: category_name }))
          this.fetching.filter.categories = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getFilterItems () {
      this.fetching.filter.items = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getItems('user=1').then(({ data }) => {
          this.list.filter.items = data.map(
            item => ({
              id: item.id,
              category_name: item.category_name,
              serial_number: item.serial_number,
              item_name: item.item_name,
              item_label: `${item.item_name} ( ${item.serial_number} )`
            })
          )
          this.fetching.filter.items = this.state.busy = false
          resolve(true)
        })
      })
    },

    onRefreshTableItemDetails () {
      this.$refs.tableItemDetails.refresh()
    },

    onViewItemDetail (itemDetail) {
      this.itemDetail.item_label = null
      this.itemDetail.item_name = null
      this.itemDetail.item_serial_number = null

      if (itemDetail.item) {
        this.itemDetail.item_name = itemDetail.item.item_name
        this.itemDetail.item_serial_number = itemDetail.item.serial_number
      }

      this.itemDetail.id = itemDetail.id
      this.itemDetail.item_detail_code = itemDetail.item_detail_code
      this.itemDetail.item_detail_name = itemDetail.item_detail_name
      this.itemDetail.serial_number = itemDetail.serial_number
      this.itemDetail.purchase_date = this.dateLongFormatter(itemDetail.purchase_date)
      this.itemDetail.original_cost = itemDetail.original_cost
      this.itemDetail.specifications = itemDetail.specifications

      this.itemDetail.type_name = itemDetail.type_name
      this.itemDetail.category_name = itemDetail.category_name
      this.itemDetail.supplier_name = itemDetail.supplier_name
      this.itemDetail.brand_name = itemDetail.brand_name

      this.$bvModal.show('modal-item-detail')
    }
  }
}
</script>
